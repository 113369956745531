const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['gymAdmin'] = require('../middleware/gymAdmin.js')
middleware['gymAdmin'] = middleware['gymAdmin'].default || middleware['gymAdmin']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

export default middleware
